import styled from '@emotion/styled';
import { ItemProps, MenuProps } from './';
import { css } from '@emotion/react';
import { getThemeColor, addAlphaToHex } from '../../utils';

const itemPadding = ({ size, withMenu }: Omit<ItemProps, 'onClick'>) => css`
	padding: ${(() => {
		switch (size) {
			case 'lg':
				return `12px ${withMenu ? 12 : 20}px 12px 0`;
			case 'sm':
			case 'xs':
				return `8px ${withMenu ? 12 : 20}px 8px 0`;
			default:
			case 'md':
				return `8px ${withMenu ? 12 : 20}px 8px 0`;
		}
	})()};
`;

export const StyledMenu = styled.div<Omit<MenuProps, 'onChange'>>`
	${({ size, theme }) => {
		switch (size) {
			case 'lg':
				return theme.typography.textRegular300;
			case 'sm':
				return theme.typography.textRegular300;
			case 'xs':
				return theme.typography.textRegular200;
			default:
			case 'md':
				return theme.typography.textRegular300;
		}
	}};
	background-color: ${({ theme: { colors }, dark }) => (dark ? colors.black[80] : colors.white)};
	display: flex;
	flex-direction: column;
	border: 1px solid #0a1b3e1a;
	box-shadow:
		0 8px 8px -4px #10182808,
		0 20px 24px -4px #10182814;
	border-radius: 12px;
	width: ${({ width }) =>
		typeof width === 'string' ? width : typeof width === 'number' ? `${width}px` : '100%'};
	min-width: ${({ minWidth }) =>
		typeof minWidth === 'string'
			? minWidth
			: typeof minWidth === 'number'
				? `${minWidth}px`
				: '40px'};
	overflow: visible;
	transition:
		visibility 200ms ease-out,
		opacity 200ms ease-out,
		transform 200ms;

	// animation of visible/hidden state of tooltip

	&[data-animation='fade'][data-state='false'] {
		visibility: hidden;
		opacity: 0;
		transform: translateY(-8px);
	}

	&[data-animation='fade'][data-state='true'] {
		visibility: visible;
		opacity: 1;
		transform: translateY(0);
	}

	> div > div:first-of-type {
		padding: 4px;
		box-sizing: border-box;
		border-radius: 12px;
	}
`;

const StyledItem = styled.label<Omit<ItemProps, 'onClick' | 'id'>>`
	position: relative;
	display: flex;
	width: 100%;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	color: ${({ disabled, theme, theme: { colors }, active, color, deleteStyle, dark }) =>
		disabled
			? theme.colors.grey_shades_with_blue[300]
			: deleteStyle
				? colors.red[600]
				: active
					? colors.primary[900]
					: dark
						? colors.white
						: getThemeColor(color, theme)};
	box-sizing: border-box;
	border-radius: 8px;
	background-color: ${({ disabled, active, theme, withMenu }) =>
		active && !disabled && !withMenu ? theme.colors.primary[175] : 'transparent'};

	&:hover {
		background-color: ${({ active, disabled, theme: { colors }, noHover, deleteStyle, dark }) =>
			!noHover && !active && !disabled
				? deleteStyle
					? colors.upgrade[100]
					: dark
						? colors.black[60]
						: addAlphaToHex(colors.primary[850], 0.1)
				: undefined};
	}

	.activeBackground {
		transition: background-color 0ms 200ms ease-out;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		position: absolute;
		background-color: ${({ disabled, active, theme }) =>
			active && !disabled ? theme.colors.primary[175] : 'transparent'};
		border-radius: 8px;
		//z-index: 1;
	}

	.itemContent {
		z-index: 2;
		display: flex;
		flex: 1 0 auto;
		width: 100%;
	}

	i {
		color: ${({ disabled, theme, active, iconColor, deleteStyle, iconActiveColor }) =>
			disabled
				? theme.colors.grey_shades_with_blue[300]
				: deleteStyle
					? theme.colors.red[600]
					: active
						? iconActiveColor
							? getThemeColor(iconActiveColor, theme)
							: `${theme.colors.white}`
						: getThemeColor(iconColor, theme)};
	}
`;

export const StyledDefaultItem = styled(StyledItem)<
	Omit<ItemProps, 'onClick' | 'id'> & { specificContext?: boolean }
>`
	align-items: center;
	// next 2 lines - break long words solution ( IE11 )
	word-break: break-all;
	overflow-wrap: break-word;
	line-height: 20px;
	${({ size, withMenu, specificContext }) =>
		!withMenu && !specificContext && itemPadding({ size, withMenu })};

	> .menu-child-wrapper {
		${itemPadding};
	}

	.renderLeft {
		z-index: 2;
		margin-left: ${({ theme }) => `${theme.helpers.spacer(2)}px`};
		display: flex;
	}

	.itemLabel {
		z-index: 2;
		display: flex;
		flex: 1 1 auto;
		align-items: center;
		margin-left: 20px;
	}

	.rightArrow {
		color: ${({ theme: { colors }, disabled }) =>
			disabled ? colors.grey_shades_with_blue[300] : colors.grey_shades_with_blue[400]};
	}

	i {
		color: ${({ theme, disabled }) =>
			disabled ? theme.colors.grey_shades_with_blue[300] : theme.colors.grey_shades_with_blue[350]};
	}
`;

export const StyledDefaultItemDiv = styled('div')(StyledDefaultItem);

export const StyledDescriptionItem = styled(StyledItem)<Omit<ItemProps, 'onClick' | 'id'>>`
	padding: 10px 14px;
	cursor: default;
	flex-direction: column;
	//align-items: flex-start;
	text-align: left;

	.label {
		${({ theme }) => theme.typography.textSemibold200};
		z-index: 2;
		margin-bottom: 4px;
	}

	.description {
		${({ theme }) => theme.typography.textRegular200};
		color: ${({ theme: { colors }, active }) =>
			active ? colors.white : colors.grey_shades_with_blue[500]};
		z-index: 2;
	}
`;

export const StyledDevider = styled.span<Omit<ItemProps, 'onClick' | 'id'>>`
	display: flex;
	border-top: 1px solid #0a1b3e1a;
	margin: 4px -4px;
`;

export const MenuChildWrapper = styled.div`
	display: inline-flex;
`;
