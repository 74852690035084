import styled from '@emotion/styled';
import { DialogProps } from './';

export const CloseButton = styled.div<DialogProps>`
	position: absolute;
	z-index: 999999;
	top: ${({ theme }) => theme.helpers.spacer(3)}px;
	right: ${({ theme }) => theme.helpers.spacer(3)}px;
	color: ${({ theme }) => theme.colors.grey_shades_with_blue[400]};
`;

export const Window = styled.div<DialogProps>`
	width: ${({ theme, width }) => width ?? theme.helpers.spacer(52)}px;
	padding: ${({ theme, padding }) => padding ?? theme.helpers.spacer(3)}px;
	background-color: ${({ theme }) => theme.colors.white};
	display: inline-flex;
	flex-direction: column;
	border-radius: 16px;
	box-sizing: border-box;
	position: relative;
	min-height: ${({ theme, minHeight }) => minHeight ?? theme.helpers.spacer(9)}px;
	overflow: hidden;
	height: 100%;
	align-items: center;
	max-height: calc(100dvh - 128px);
	box-shadow:
		0 8px 8px -4px #10182808,
		0 20px 24px -4px #10182814;
`;

export const StyledDialog = styled.div<DialogProps>`
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	z-index: 9999;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	background-color: #40454e33;
	backdrop-filter: blur(12px);

	.animationWrapper {
		display: flex;
		flex: 1 0 auto;
		height: 100dvh;
		flex-direction: column;
		justify-content: center;
	}
`;

export const DialogAnimationWrapper = styled.div`
	.fade-appear {
		opacity: 0;
	}

	.fade-appear-active {
		opacity: 1;
		transition: opacity 200ms ease-in;
	}
	.scale-appear {
		transform: scale(0.95);
	}

	.scale-appear-active {
		transform: scale(1);
		transition: transform 200ms ease-in-out;
	}

	.prev {
		flex-direction: column-reverse !important;

		.slide-exit-active {
			margin: 0 0 -100vh 0 !important;
		}
	}

	.slide-exit-active {
		margin: -100vh 0 0 0;
	}

	.slide-enter-active,
	.slide-exit-active {
		transition: margin 300ms ease-out;
	}
`;
