import gql from 'graphql-tag';

export default gql`
	query priceList($countryCode: String) {
		priceList(countryCode: $countryCode) {
			mini {
				...PricePlan
			}
			standard {
				...PricePlan
			}
			plus {
				...PricePlan
			}
			starter {
				...PriceProPlan
			}
			medium {
				...PriceProPlan
			}
			enterprise {
				...PriceProPlan
			}
			currencyCode
		}
	}

	fragment PricePlan on PricePlan {
		monthlyAmount
		yearlyAmount
		twoYearAmount
		currencyCode
		responseLimit
	}

	fragment PriceProPlan on PriceProPlan {
		monthlyAmount
		yearlyAmount
		twoYearAmount
		currencyCode
		yearSeatPrice
		twoYearSeatPrice
		seatsDefault
		seatsMax
		responseLimit
	}
`;
