import styled from '@emotion/styled';

import { NavigationProps } from './';
import { Typo } from '../Typography';
import { AvatarWrapper } from '../Avatar';
import { StyledIcon } from '../Icon';
import { StyledMoreToneIcon } from '../MoreToneIcon';
import { Slider } from '~/ui/components/Switch';

export const StyledNavigation = styled.div`
	width: auto;
	height: 100%;
	text-align: left;
	flex: 1 0;
	display: flex;
	flex-direction: column;
`;

export const StyledLeftBarContent = styled.div<NavigationProps>`
	padding: ${({ variant }) => (variant === 'navigation' ? '0 20px' : '0')};
	overflow-y: hidden;
	position: relative;
	width: 100%;
	text-align: left;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: ${({ variant }) => (variant === 'navigation' ? 'stretch' : 'flex-start')};
`;

export const StyledScrollBarArea = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-x: hidden;
	overflow-y: auto;

	> * {
		display: flex;
		flex-direction: column;
	}

	.scrollbarView {
		position: relative !important;
		overflow-x: hidden !important;
		overflow-y: scroll !important;
		margin-bottom: 0 !important;
	}
`;

export const StyledStickyFooter = styled.div<NavigationProps>`
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	&:before {
		height: 32px;
		visibility: ${({ overflowing, bottomReached }) =>
			overflowing && !bottomReached ? 'visible' : 'hidden'};
		opacity: ${({ overflowing, bottomReached }) => (overflowing && !bottomReached ? 1 : 0)};
		width: calc(100% - 16px);
		content: '';
		display: flex;
		position: absolute;
		top: -32px;
		background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 87.5%);
		transition: all 200ms ease-out;
	}
`;

export const StyledTourWrapper = styled.div`
	border-top: 1px solid ${({ theme }) => theme.colors.grey_shades_with_blue[50]};
	padding: 8px 20px;
`;

export const StyledFooterChildren = styled.div<NavigationProps>`
	padding: ${({ overflowing, bottomReached }) => (overflowing && !bottomReached ? '8px' : 0)} 20px
		6px 20px;
	transition: all 200ms ease-out;
`;

export const StyledHeader = styled.div<NavigationProps>`
	padding: ${({ variant }) => (variant === 'navigation' ? '8px 16px' : '8px 12px')};
	${({ variant }) => (variant === 'categories' ? 'margin-bottom: 16px' : '')};

	${Typo} {
		height: ${({ variant }) => (variant === 'navigation' ? '44px' : 'auto')};
		display: flex;
		align-items: center;

		> ${AvatarWrapper} {
			margin-left: -4px;
			margin-right: 20px;
		}

		> *:not(${AvatarWrapper}):not([data-tippy-root]) {
			margin-left: auto;
		}
	}
`;

export const StyledItem = styled.div<NavigationProps>`
	position: relative;
	cursor: pointer;
	padding: ${({ variant }) => (variant === 'navigation' ? '0 16px 0 10px' : '10px 12px')};
	border-radius: 8px;
	color: ${({ active, theme, turnedOff }) =>
		turnedOff
			? theme.colors.grey_shades_with_blue[300]
			: active
				? theme.colors.primary[850]
				: theme.colors.grey_shades_with_blue[500]};

	&:not(:last-of-type) {
		margin-bottom: 2px;
	}

	background: ${({ hover, active, theme: { colors }, variant, turnedOff }) =>
		active
			? variant === 'navigation'
				? turnedOff
					? colors.grey_shades_with_blue[25]
					: colors.primary[150]
				: colors.grey[300]
			: hover
				? colors.grey['300']
				: 'none'};

	&:hover {
		background: ${({ active, variant, theme: { colors }, turnedOff }) =>
			active
				? variant === 'navigation'
					? turnedOff
						? colors.grey_shades_with_blue[25]
						: colors.primary[150]
					: colors.grey[300]
				: colors.grey_shades_with_blue[25]};
		color: ${({ variant, theme, turnedOff, active }) => {
			switch (true) {
				case turnedOff:
					return theme.colors.grey_shades_with_blue[300];
				case variant === 'navigation':
					return active ? theme.colors.primary[850] : theme.colors.grey_shades_with_blue[500];
				default:
					return theme.colors.black[100];
			}
		}};
	}

	${Typo} {
		height: ${({ variant }) => (variant === 'navigation' ? '44px' : 'auto')};
		display: flex;
		align-items: center;

		> ${StyledMoreToneIcon}, > ${StyledIcon} {
			margin-right: 12px;
		}

		> *:not(${StyledMoreToneIcon}):not(${StyledIcon}):not([data-tippy-root]) {
			margin-left: ${({ variant }) => (variant === 'navigation' ? 'auto' : 'unset')};
		}
	}

	${Slider} {
		${({ theme, turnedOff }) =>
			turnedOff ? `background-color: ${theme.colors.grey_shades_with_blue[250]}` : ''};
	}

	${StyledIcon} {
		${({ theme: { colors }, active, variant, turnedOff }) =>
			variant === 'navigation' &&
			`color: ${
				turnedOff
					? colors.grey_shades_with_blue[200]
					: active
						? colors.primary[850]
						: colors.grey_shades_with_blue[350]
			}`};
	}
`;

export const StyledDevider = styled.div`
	height: 1px;
	width: calc(100% + 16px);
	margin: 24px 0 24px -16px;
	background: #ebebeb;
`;

export const StyledMoreButton = styled.div<NavigationProps>`
	position: absolute;
	bottom: 8px;
	border: 1px solid rgba(255, 255, 255, 0.8);
	background: rgba(0, 0, 0, 0.04);
	backdrop-filter: blur(7px);
	border-radius: 12px;
	padding: 10px 24px;
	width: calc(100% - 48px);
	display: flex;
	opacity: ${({ overflowing, bottomReached }) => (overflowing && !bottomReached ? 1 : 0)};
	visibility: ${({ overflowing, bottomReached }) =>
		overflowing && !bottomReached ? 'visible' : 'hidden'};
	transition: all 200ms ease-out;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	color: ${({ theme }) => theme.colors.grey_shades_with_blue[500]};
	${({ theme }) => theme.typography.textMedium250};
	cursor: pointer;
	z-index: 100;
	i {
		margin-right: 8px;
	}
`;

export const StyledCategory = styled.div<NavigationProps>`
	display: flex;
	${({ theme }) => theme.typography.textSemibold300};
	padding: 10px 12px;
	align-items: center;
	cursor: pointer;

	i {
		margin-left: 2px;
	}
`;
