import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Menu } from '~/ui';
import { addAlphaToHex, Button } from 'ui';
import { Modal } from '@survio/ui';
import Iframe from './components/Iframe';
import { useIntl } from 'react-intl';
import styles from './styles.less';

const InAppModal = ({ onClose, url, language, onLoad }) => {
	const intl = useIntl();

	const handleClose = useCallback(
		(skipped) => () => {
			onClose(skipped);
		},
		[onClose],
	);

	return (
		<Modal slide="iframe" close={handleClose} hideCloseBtnOnSlides>
			{(slide) => {
				switch (slide) {
					case 'iframe':
						return (
							<>
								<Iframe url={url} language={language} onLoad={onLoad} />

								<div className={styles.close}>
									<Menu
										dark
										size="sm"
										offset={[0, 0]}
										minWidth={192}
										placement="left"
										popperOptions={{
											strategy: 'fixed',
										}}
										items={[
											{
												id: 'close',
												onClick: handleClose(true),
												label: intl.formatMessage({ id: 'app.product-tour-try-next-time' }),
											},
											{
												id: 'skip',
												onClick: handleClose(false),
												label: intl.formatMessage({ id: 'app.product-tour-skip-completely' }),
											},
										]}
									>
										<Button
											color="ghost"
											size="md"
											startIcon="close-24"
											variant="iconButton"
											className="close"
										/>
									</Menu>
								</div>
							</>
						);
				}
			}}
		</Modal>
	);
};

InAppModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	url: PropTypes.string.isRequired,
	language: PropTypes.string.isRequired,
	onLoad: PropTypes.func.isRequired,
};

export default memo(InAppModal);
