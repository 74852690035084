import React, { useRef, useEffect, memo, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@survio/ui';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';
import { openModal } from '~/actions';
import withCleverbridge from '~/hoc/withCleverbridge';
import { TYPES } from '~/pages/Pricing/constants';
import query from '~/cache/query';
import { isProPlan } from '~/utils';
import PRICE_LIST from '~/cache/queries/user/priceList';
import { events } from '../constants';
import SaleSourceContext from '~/components/UpgradeModalStripe/SaleSourceContext';
import withRouter from '~/hoc/withRouter';
import mutation from '~/cache/mutation';

import styles from '../styles.less';

const Iframe = ({
	url,
	language,
	cleverbridge,
	close,
	openModal,
	priceList,
	stripePriceList,
	isBusinessModel01,
	sendTracking,
	trigger,
	testPrice23A,
	testPrice24A,
	planName,
	planIdentifier,
	onLoad,
	history,
	setUpgradeModal,
}) => {
	const iframe = useRef();
	const eventVersion = useRef();
	const _didUpgrade = useRef(false);
	const feature = new URL(url).searchParams.get('msg_id');
	const { setSaleSource } = useContext(SaleSourceContext);

	const setSlide = useCallback(
		(plan) => {
			close();
			setUpgradeModal({ isOpen: true, plan });
		},
		[close, setUpgradeModal],
	);

	const trackEvent = useCallback(
		(event) => {
			if (Array.isArray(event)) {
				event.forEach((e) => sendTracking(e.id, e.name, eventVersion.current));
			} else {
				sendTracking(event.id, event.name, eventVersion.current);
			}
		},
		[sendTracking],
	);

	useEffect(
		() => () => {
			if (!_didUpgrade.current && trigger) {
				const { close } = events[trigger];
				trackEvent(close);
			}
		},
		[trackEvent, trigger],
	);

	useEffect(() => {
		window.addEventListener(
			'message',
			async (message) => {
				if (message.source !== iframe.current?.contentWindow) {
					return;
				}

				const msg = JSON.parse(message?.data);
				const { open, activate } = events[trigger];

				switch (msg?.event) {
					case 'onLoad':
						iframe.current?.contentWindow.postMessage(
							JSON.stringify({
								language,
								cleverbridge,
								priceList,
								stripePriceList,
								isBusinessModel01,
								testPrice23A,
								testPrice24A,
								planName,
								planIdentifier: cleverbridge ? TYPES[planIdentifier].toLowerCase() : planIdentifier,
							}),
							'*',
						);
						eventVersion.current = msg?.data?.version;

						return trackEvent(open);
					case 'active_service':
						const saleSource = {
							section: 'app',
							element: activate[0].name,
							version: eventVersion.current,
						};

						if (msg.data.redirect) {
							history.push({
								pathname: msg.data.redirect,
								state: { saleSource },
							});
							close();
							return;
						}

						_didUpgrade.current = true;

						setSaleSource(saleSource);

						if (cleverbridge) {
							close();
							const UpgradeModal = (await import('~/components/UpgradeModal')).default;
							const pro = isProPlan(TYPES[msg?.data?.service]);
							openModal(
								{
									upgrade: <UpgradeModal />,
								},
								'upgrade',
								{
									saleSource,
									type: TYPES[msg?.data?.service],
									period: pro ? 'YEARLY' : 'MONTHLY',
									selectedPeriod: 'YEARLY',
								},
							);
						} else {
							setSlide(msg?.data?.service || 'upgradePRO');
						}

						return trackEvent(activate);
				}
			},
			false,
		);
	}, [
		cleverbridge,
		close,
		isBusinessModel01,
		language,
		openModal,
		planIdentifier,
		planName,
		priceList,
		setSlide,
		stripePriceList,
		testPrice23A,
		testPrice24A,
		trackEvent,
		trigger,
	]);

	return (
		<Dialog onClose={close}>
			<Dialog.Window padding={0} className={styles.dialog}>
				<iframe
					allow="fullscreen"
					ref={iframe}
					width="100%"
					height="100%"
					frameBorder="0"
					src={url.replace('{lang}', language)}
					onLoad={onLoad}
				/>
			</Dialog.Window>
		</Dialog>
	);
};

Iframe.propTypes = {
	url: PropTypes.string.isRequired,
	language: PropTypes.string.isRequired,
	cleverbridge: PropTypes.bool,
	close: PropTypes.func.isRequired,
	openModal: PropTypes.func.isRequired,
	period: PropTypes.string,
	priceList: PropTypes.array,
	stripePriceList: PropTypes.array,
	isBusinessModel01: PropTypes.bool,
	sendTracking: PropTypes.func.isRequired,
	trigger: PropTypes.string,
	testPrice23A: PropTypes.bool,
	testPrice24A: PropTypes.bool,
	planName: PropTypes.string,
	planIdentifier: PropTypes.string,
	onLoad: PropTypes.func,
};

export default compose(
	memo,
	connect(null, {
		openModal,
	}),
	withRouter,
	mutation('setUpgradeModal'),
	withCleverbridge,
	query('account', {
		fragments: ['ActiveService'],
		mapProps: ({ user }) => ({
			period: user?.activeService?.period,
			currencyCode: user?.activeService?.nextBilling?.currencyCode,
			planName: user?.activeService?.name,
			planIdentifier: user?.activeService?.identifier,
		}),
	}),
	graphql(PRICE_LIST, {
		props: ({ data: { priceList } }) => ({
			priceList,
		}),
	}),
	query('stripe', {
		fragments: ['Prices'],
		mapProps: ({ stripePriceList }) => {
			console.log('stripePriceList', stripePriceList);
			return {
				stripePriceList,
			};
		},
	}),
	query('user', {
		fragments: ['Experiments'],
		mapProps: ({ user }) => ({
			testPrice23A: user.experiments.some(
				({ name, variant }) => name === 'test_price' && variant === '23_A',
			),
			testPrice24A: user.experiments.some(
				({ name, variant }) => name === 'test_price' && variant === '24_A',
			),
		}),
	}),
)(Iframe);
