import React, { useCallback } from 'react';
import { compose } from 'redux';
import withRouter from '~/hoc/withRouter';
import query from '~/cache/query';
import mutation from '~/cache/mutation';
import { AB_BM_VARIANT, EXPERIMENT_STATE, EXPERIMENTS } from '~/constants';

const withUpgradeModal =
	(Component) =>
	({ setUpgradeModal, ...props }) => {
		const openStripeUpgradeModal = useCallback(
			(parameters) => setUpgradeModal({ isOpen: true, ...parameters }),
			[setUpgradeModal],
		);
		const closeStripeUpgradeModal = useCallback(
			() =>
				setUpgradeModal({
					isOpen: false,
					upgradeType: null,
					period: null,
					plan: null,
					salesVariant: null,
					iframeProps: null,
					saleSource: null,
				}),
			[setUpgradeModal],
		);
		return (
			<Component
				{...props}
				openStripeUpgradeModal={openStripeUpgradeModal}
				closeStripeUpgradeModal={closeStripeUpgradeModal}
			/>
		);
	};

export default compose(
	withRouter,
	mutation('setUpgradeModal'),
	query('stripeModal', {
		mapProps: ({ stripeModal }) => ({
			isUpgradeModalOpen: stripeModal?.upgradeModalData?.isOpen,
		}),
	}),
	query('user', {
		fragments: ['Experiments'],
		mapProps: (data) => {
			const isBusinessModel01 =
				data?.user?.experiments?.some(
					(e) =>
						e.name === EXPERIMENTS.AB_BUSINESS_MODEL &&
						e.variant === AB_BM_VARIANT.V1 &&
						e.state === EXPERIMENT_STATE.INCLUDED,
				) || false;
			return { isBusinessModel01 };
		},
	}),
	withUpgradeModal,
);
