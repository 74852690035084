import styled from '@emotion/styled';
import { Tooltip, TooltipWrapper } from '~/ui/components/Tooltip';

export const ToUrlOrSubmitStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	padding: 0 12px;

	.to-icon {
		background: #2323511a;
		border-radius: 12px;
		padding: 4px 8px;
	}

	.toSubmit-apply {
		width: 104px;
	}
`;

export const ToQuestionOrPageStyled = styled(ToUrlOrSubmitStyled)`
	align-items: flex-start;
	justify-content: flex-start;
	gap: 2px;
	padding: 12px 0;
	height: fit-content;

	.fullWidth {
		width: 100%;

		.tooltip-children {
			width: 100%;
		}
	}
`;

export const InLine = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	width: 100%;

	.url-input {
		width: 100%;
	}
`;

export const StyledItem = styled.div<{ active: boolean; disabled: boolean }>`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	${({ theme }) => theme.typography.textRegular300};
	color: ${({ theme: { colors }, active, disabled }) =>
		disabled ? colors.grey_shades_with_blue[300] : active ? colors.primary[900] : colors.black};
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	border-radius: 8px;
	padding: 8px;
	background: ${({ theme: { colors }, active }) => (active ? colors.primary[175] : 'transparent')};

	:hover {
		background: ${({ theme: { colors }, active, disabled }) =>
			disabled ? '' : active ? colors.primary[175] : colors.grey_shades_with_blue[25]};
	}

	.checkmark-icon {
		margin-left: auto;
	}
`;
