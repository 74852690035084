export const events = {
	prod_logo_builder_click_sales_v0: {
		open: [
			{ id: 0, name: 'prod_logo_builder_open_sales_v0' },
			{ id: 57, name: 'builder_click_upsell_feature_logo' },
		],
		activate: [
			{ id: 0, name: 'prod_logo_builder_cta_sales_v0' },
			{ id: 58, name: 'builder_click_upsell_feature_logo_ActivatePremium' },
		],
		close: [
			{ id: 0, name: 'prod_logo_builder_close_sales_v0' },
			{ id: 59, name: 'builder_click_upsell_feature_logo_closeWindow' },
		],
	},
	prod_logo_welcome_click_sales_v0: {
		open: [
			{ id: 0, name: 'prod_logo_welcome_open_sales_v0' },
			{ id: 57, name: 'builder_click_upsell_feature_logo' },
		],
		activate: [
			{ id: 0, name: 'prod_logo_welcome_cta_sales_v0' },
			{ id: 58, name: 'builder_click_upsell_feature_logo_ActivatePremium' },
		],
		close: [
			{ id: 0, name: 'prod_logo_welcome_close_sales_v0' },
			{ id: 59, name: 'builder_click_upsell_feature_logo_closeWindow' },
		],
	},
	'prod_e-inv_get-resp_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_e-inv_get-resp_open_sales_v0' },
			{ id: 98, name: 'builder_sharelink_click_upsell_feature_sendInvitations' },
		],
		activate: [
			{ id: 0, name: 'prod_e-inv_get-resp_cta_sales_v0' },
			{
				id: 99,
				name: 'builder_sharelink_click_upsell_feature_sendInvitations_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_e-inv_get-resp_close_sales_v0' },
			{
				id: 100,
				name: 'builder_sharelink_click_upsell_feature_sendInvitations_closeWindow',
			},
		],
	},
	'prod_e-inv_builder-share-btn_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_e-inv_builder-share-btn_open_sales_v0' },
			{ id: 98, name: 'builder_sharelink_click_upsell_feature_sendInvitations' },
		],
		activate: [
			{ id: 0, name: 'prod_e-inv_builder-share-btn_cta_sales_v0' },
			{
				id: 99,
				name: 'builder_sharelink_click_upsell_feature_sendInvitations_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_e-inv_builder-share-btn_close_sales_v0' },
			{
				id: 100,
				name: 'builder_sharelink_click_upsell_feature_sendInvitations_closeWindow',
			},
		],
	},
	'prod_pdf-report_results-pdf-btn_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_pdf-report_results-pdf-btn_open_sales_v0' },
			{ id: 104, name: 'results_analyze_click_upsell_feature_PDFexport' },
		],
		activate: [
			{ id: 0, name: 'prod_pdf-report_results-pdf-btn_cta_sales_v0' },
			{ id: 105, name: 'results_analyze_click_upsell_feature_PDFexport_ActivatePremium' },
		],
		close: [
			{ id: 0, name: 'prod_pdf-report_results-pdf-btn_close_sales_v0' },
			{ id: 106, name: 'results_analyze_click_upsell_feature_PDFexport_closeWindow' },
		],
	},
	'prod_xlsx-export_results-xls-btn_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_xlsx-export_results-xls-btn_open_sales_v0' },
			{ id: 113, name: 'results_analyze_click_upsell_feature_XLSexport' },
		],
		activate: [
			{ id: 0, name: 'prod_xlsx-export_results-xls-btn_cta_sales_v0' },
			{ id: 114, name: 'results_analyze_click_upsell_feature_XLSexport_ActivatePremium' },
		],
		close: [
			{ id: 0, name: 'prod_xlsx-export_results-xls-btn_close_sales_v0' },
			{ id: 115, name: 'results_analyze_click_upsell_feature_XLSexport_closeWindow' },
		],
	},
	'prod_google-sheets_settings-integrations_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_google-sheets_settings-integrations_open_sales_v0' },
			{ id: 140, name: 'integrations_click_upsell_feature_GoogleSheets' },
		],
		activate: [
			{ id: 0, name: 'prod_google-sheets_settings-integrations_cta_sales_v0' },
			{ id: 141, name: 'integrations_click_upsell_feature_GoogleSheets_ActivatePremium' },
		],
		close: [
			{ id: 0, name: 'prod_google-sheets_settings-integrations_close_sales_v0' },
			{ id: 142, name: 'integrations_click_upsell_feature_GoogleSheets_windowClose' },
		],
	},
	'prod_docx-report_results-doc-btn_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_docx-report_results-doc-btn_open_sales_v0' },
			{ id: 107, name: 'results_analyze_click_upsell_feature_DOCexport' },
		],
		activate: [
			{ id: 0, name: 'prod_docx-report_results-doc-btn_cta_sales_v0' },
			{ id: 108, name: 'results_analyze_click_upsell_feature_DOCexport_ActivatePremium' },
		],
		close: [
			{ id: 0, name: 'prod_docx-report_results-doc-btn_close_sales_v0' },
			{ id: 109, name: 'results_analyze_click_upsell_feature_DOCexport_closeWindow' },
		],
	},
	'prod_pptx-report_results-ppt-btn_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_pptx-report_results-ppt-btn_open_sales_v0' },
			{ id: 110, name: 'results_analyze_click_upsell_feature_PPTexport' },
		],
		activate: [
			{ id: 0, name: 'prod_pptx-report_results-ppt-btn_cta_sales_v0' },
			{ id: 111, name: 'results_analyze_click_upsell_feature_PPTexport_ActivatePremium' },
		],
		close: [
			{ id: 0, name: 'prod_pptx-report_results-ppt-btn_close_sales_v0' },
			{ id: 112, name: 'results_analyze_click_upsell_feature_PPTexport_closeWindow' },
		],
	},
	'prod_csv-export_results-csv-btn_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_csv-export_results-csv-btn_open_sales_v0' },
			{ id: 116, name: 'results_analyze_click_upsell_feature_CSVexport' },
		],
		activate: [
			{ id: 0, name: 'prod_csv-export_results-csv-btn_cta_sales_v0' },
			{ id: 117, name: 'results_analyze_click_upsell_feature_CSVexport_ActivatePremium' },
		],
		close: [
			{ id: 0, name: 'prod_csv-export_results-csv-btn_close_sales_v0' },
			{ id: 118, name: 'results_analyze_click_upsell_feature_CSVexport_closeWindow' },
		],
	},
	'prod_respondent-redirect_settings-adv_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_respondent-redirect_settings-adv_open_sales_v0' },
			{ id: 88, name: 'builder_preferences_click_upsell_feature_respondentRedirect' },
		],
		activate: [
			{ id: 0, name: 'prod_respondent-redirect_settings-adv_cta_sales_v0' },
			{
				id: 89,
				name: 'builder_preferences_click_upsell_feature_respondentRedirect_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_respondent-redirect_settings-adv_close_sales_v0' },
			{
				id: 90,
				name: 'builder_preferences_click_upsell_feature_respondentRedirect_closeWindow',
			},
		],
	},
	'prod_responses-in-email_settings-adv_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_responses-in-email_settings-adv_open_sales_v0' },
			{ id: 82, name: 'builder_preferences_click_upsell_feature_notificationsSurveyAnswers' },
		],
		activate: [
			{ id: 0, name: 'prod_responses-in-email_settings-adv_cta_sales_v0' },
			{
				id: 83,
				name: 'builder_preferences_click_upsell_feature_notificationsSurveyAnswers_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_responses-in-email_settings-adv_close_sales_v0' },
			{
				id: 84,
				name: 'builder_preferences_click_upsell_feature_notificationsSurveyAnswers_closeWindow',
			},
		],
	},
	'prod_survey-lang_settings_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_survey-lang_settings_open_sales_v0' },
			{ id: 354, name: 'builder_preferences_click_upsell_feature_surveylanguage_create_custom' },
		],
		activate: [
			{ id: 0, name: 'prod_survey-lang_settings_cta_sales_v0' },
			{
				id: 355,
				name:
					'builder_preferences_click_upsell_feature_surveylanguage_create_custom_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_survey-lang_settings_close_sales_v0' },
			{
				id: 356,
				name: 'builder_preferences_click_upsell_feature_surveylanguage_create_custom_windowClose',
			},
		],
	},
	'prod_get-params_settings-adv_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_get-params_settings-adv_open_sales_v0' },
			{ id: 85, name: 'builder_preferences_click_upsell_feature_customURLparams' },
		],
		activate: [
			{ id: 0, name: 'prod_get-params_settings-adv_cta_sales_v0' },
			{
				id: 86,
				name: 'builder_preferences_click_upsell_feature_customURLparams_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_get-params_settings-adv_close_sales_v0' },
			{
				id: 87,
				name: 'builder_preferences_click_upsell_feature_customURLparams_closeWindow',
			},
		],
	},
	'prod_survey-indexing_settings-adv_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_survey-indexing_settings-adv_open_sales_v0' },
			{ id: 362, name: 'builder_preferences_upsell_feature_allowindexing' },
		],
		activate: [
			{ id: 0, name: 'prod_survey-indexing_settings-adv_cta_sales_v0' },
			{
				id: 410,
				name: 'builder_preferences_upsell_feature_allowindexing_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_survey-indexing_settings-adv_close_sales_v0' },
			{
				id: 411,
				name: 'builder_preferences_upsell_feature_allowindexing_closeWindow',
			},
		],
	},
	'prod_google-analytics_settings-integrations_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_google-analytics_settings-integrations_open_sales_v0' },
			{ id: 91, name: 'builder_preferences_click_upsell_feature_GoogleAnalytics' },
		],
		activate: [
			{ id: 0, name: 'prod_google-analytics_settings-integrations_cta_sales_v0' },
			{
				id: 92,
				name: 'builder_preferences_click_upsell_feature_GoogleAnalytics_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_google-analytics_settings-integrations_close_sales_v0' },
			{
				id: 93,
				name: 'builder_preferences_click_upsell_feature_GoogleAnalytics_closeWindow',
			},
		],
	},
	'prod_zapier_settings-integrations_click_sales_v0': {
		open: [{ id: 0, name: 'prod_zapier_settings-integrations_open_sales_v0' }],
		activate: [{ id: 0, name: 'prod_zapier_settings-integrations_cta_sales_v0' }],
		close: [{ id: 0, name: 'prod_zapier_settings-integrations_close_sales_v0' }],
	},
	'prod_server-loc_settings_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_server-loc_settings_open_sales_v0' },
			{ id: 515, name: 'profile_click_upsell_feature_geolocation' },
		],
		activate: [
			{ id: 0, name: 'prod_server-loc_settings_cta_sales_v0' },
			{ id: 516, name: 'profile_click_upsell_feature_geolocation_ActivatePremium' },
		],
		close: [
			{ id: 0, name: 'prod_server-loc_settings_close_sales_v0' },
			{ id: 517, name: 'profile_click_upsell_feature_geolocation_closeWindow' },
		],
	},
	'prod_google-drive_settings-integrations_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_google-drive_settings-integrations_open_sales_v0' },
			{ id: 131, name: 'integrations_click_upsell_feature_GoogleDrive' },
		],
		activate: [
			{ id: 0, name: 'prod_google-drive_settings-integrations_cta_sales_v0' },
			{
				id: 132,
				name: 'integrations_click_upsell_feature_GoogleDrive_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_google-drive_settings-integrations_close_sales_v0' },
			{
				id: 133,
				name: 'integrations_click_upsell_feature_GoogleDrive_windowClose',
			},
		],
	},
	'prod_dropbox_settings-integrations_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_dropbox_settings-integrations_open_sales_v0' },
			{ id: 134, name: 'integrations_click_upsell_feature_Dropbox' },
		],
		activate: [
			{ id: 0, name: 'prod_dropbox_settings-integrations_cta_sales_v0' },
			{
				id: 135,
				name: 'integrations_click_upsell_feature_Dropbox_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_dropbox_settings-integrations_close_sales_v0' },
			{
				id: 136,
				name: 'integrations_click_upsell_feature_Dropbox_windowClose',
			},
		],
	},
	'prod_onedrive_settings-integrations_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_onedrive_settings-integrations_open_sales_v0' },
			{ id: 137, name: 'integrations_click_upsell_feature_Onedrive' },
		],
		activate: [
			{ id: 0, name: 'prod_onedrive_settings-integrations_cta_sales_v0' },
			{
				id: 138,
				name: 'integrations_click_upsell_feature_Onedrive_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_onedrive_settings-integrations_close_sales_v0' },
			{
				id: 139,
				name: 'integrations_click_upsell_feature_Onedrive_windowClose',
			},
		],
	},
	'prod_nps-question_builder_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_nps-question_builder_open_sales_v0' },
			{ id: 66, name: 'builder_click_upsell_feature_NPSquestion' },
		],
		activate: [
			{ id: 0, name: 'prod_nps-question_builder_cta_sales_v0' },
			{
				id: 67,
				name: 'builder_click_upsell_feature_NPSquestion_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_nps-question_builder_close_sales_v0' },
			{
				id: 68,
				name: 'builder_click_upsell_feature_NPSquestion_closeWindow',
			},
		],
	},
	'prod_txt-btwn-questions-img_builder_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_txt-btwn-questions-img_builder_click_sales_v0' },
			{ id: 348, name: 'builder_click_upsell_feature_insertimage' },
		],
		activate: [
			{ id: 0, name: 'prod_txt-btwn-questions-img_builder_cta_sales_v0' },
			{
				id: 349,
				name: 'builder_click_upsell_feature_insertimage_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_txt-btwn-questions-img_builder_close_sales_v0' },
			{
				id: 350,
				name: 'builder_click_upsell_feature_insertimage_windowClose',
			},
		],
	},
	'prod_txt-btwn-questions-url-link_builder_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_txt-btwn-questions-url-link_builder_open_sales_v0' },
			{ id: 345, name: 'builder_click_upsell_feature_insertlink' },
		],
		activate: [
			{ id: 0, name: 'prod_txt-btwn-questions-url-link_builder_cta_sales_v0' },
			{
				id: 346,
				name: 'builder_click_upsell_feature_insertlink_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_txt-btwn-questions-url-link_builder_close_sales_v0' },
			{
				id: 347,
				name: 'builder_click_upsell_feature_insertlink_windowClose',
			},
		],
	},
	'prod_cust-thx-page_builder_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_cust-thx-page_builder_open_sales_v0' },
			{ id: 63, name: 'builder_click_upsell_feature_thankyoupage' },
		],
		activate: [
			{ id: 0, name: 'prod_cust-thx-page_builder_cta_sales_v0' },
			{
				id: 64,
				name: 'builder_click_upsell_feature_thankyoupage_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_cust-thx-page_builder_close_sales_v0' },
			{
				id: 65,
				name: 'builder_click_upsell_feature_thankyoupage_closeWindow',
			},
		],
	},
	'prod_cust-thx-page_builder-arrow_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_cust-thx-page_builder-arrow_open_sales_v0' },
			{ id: 63, name: 'builder_click_upsell_feature_thankyoupage' },
		],
		activate: [
			{ id: 0, name: 'prod_cust-thx-page_builder-arrow_cta_sales_v0' },
			{
				id: 64,
				name: 'builder_click_upsell_feature_thankyoupage_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_cust-thx-page_builder-arrow_close_sales_v0' },
			{
				id: 65,
				name: 'builder_click_upsell_feature_thankyoupage_closeWindow',
			},
		],
	},
	'prod_edit-footer_builder-ad_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_edit-footer_builder-ad_open_sales_v0' },
			{ id: 79, name: 'builder_preferences_click_upsell_feature_survioAds' },
		],
		activate: [
			{ id: 0, name: 'prod_edit-footer_builder-ad_cta_sales_v0' },
			{
				id: 80,
				name: 'builder_preferences_click_upsell_feature_survioAds_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_edit-footer_builder-ad_close_sales_v0' },
			{
				id: 81,
				name: 'builder_preferences_click_upsell_feature_survioAds_closeWindow',
			},
		],
	},
	'prod_edit-footer_builder-footer_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_edit-footer_builder-footer_open_sales_v0' },
			{ id: 351, name: 'builder_click_upsell_feature_editfooter' },
		],
		activate: [
			{ id: 0, name: 'prod_edit-footer_builder-footer_cta_sales_v0' },
			{
				id: 352,
				name: 'builder_click_upsell_feature_editfooter_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_edit-footer_builder-footer_close_sales_v0' },
			{
				id: 353,
				name: 'builder_click_upsell_feature_editfooter_windowClose',
			},
		],
	},
	'prod_cust-survey-url_get-resp_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_cust-survey-url_get-resp_open_sales_v0' },
			{ id: 101, name: 'builder_sharelink_click_upsell_feature_customizeURL' },
		],
		activate: [
			{ id: 0, name: 'prod_cust-survey-url_get-resp_cta_sales_v0' },
			{
				id: 102,
				name: 'builder_sharelink_click_upsell_feature_customizeURL_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_cust-survey-url_get-resp_close_sales_v0' },
			{
				id: 103,
				name: 'builder_sharelink_click_upsell_feature_customizeURL_closeWindow',
			},
		],
	},
	'prod_cust-survey-url_builder-share-btn_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_cust-survey-url_builder-share-btn_open_sales_v0' },
			{ id: 101, name: 'builder_sharelink_click_upsell_feature_customizeURL' },
		],
		activate: [
			{ id: 0, name: 'prod_cust-survey-url_builder-share-btn_cta_sales_v0' },
			{
				id: 102,
				name: 'builder_sharelink_click_upsell_feature_customizeURL_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_cust-survey-url_builder-share-btn_close_sales_v0' },
			{
				id: 103,
				name: 'builder_sharelink_click_upsell_feature_customizeURL_closeWindow',
			},
		],
	},
	'prod_cust-sm-post_get-resp_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_cust-sm-post_get-resp_open_sales_v0' },
			{ id: 365, name: 'builder_sharelink_click_upsell_feature_socialnetsharesettings' },
		],
		activate: [
			{ id: 0, name: 'prod_cust-sm-post_get-resp_cta_sales_v0' },
			{
				id: 366,
				name: 'builder_sharelink_click_upsell_feature_socialnetsharesettings_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_cust-sm-post_get-resp_close_sales_v0' },
			{
				id: 367,
				name: 'builder_sharelink_click_upsell_feature_socialnetsharesettings_closeWindow',
			},
		],
	},
	'prod_cust-sm-post_builder-share-btn_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_cust-sm-post_builder-share-btn_open_sales_v0' },
			{ id: 365, name: 'builder_sharelink_click_upsell_feature_socialnetsharesettings' },
		],
		activate: [
			{ id: 0, name: 'prod_cust-sm-post_builder-share-btn_cta_sales_v0' },
			{
				id: 366,
				name: 'builder_sharelink_click_upsell_feature_socialnetsharesettings_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_cust-sm-post_builder-share-btn_close_sales_v0' },
			{
				id: 367,
				name: 'builder_sharelink_click_upsell_feature_socialnetsharesettings_closeWindow',
			},
		],
	},
	'prod_share-results_results_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_share-results_results_open_sales_v0' },
			{ id: 128, name: 'results_shareresults_click_upsell_feature_shareResultsWeb_deprecated' },
		],
		activate: [
			{ id: 0, name: 'prod_share-results_results_cta_sales_v0' },
			{
				id: 129,
				name:
					'results_shareresults_click_upsell_feature_shareResultsWeb_ActivatePremium_deprecated',
			},
		],
		close: [
			{ id: 0, name: 'prod_share-results_results_close_sales_v0' },
			{
				id: 130,
				name: 'results_shareresults_click_upsell_feature_shareResultsWeb_windowClose_deprecated',
			},
		],
	},
	'prod_multiple-filters_results_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_share-results_results_open_sales_v0' },
			{ id: 125, name: 'results_analyze_click_upsell_feature_addFilter' },
		],
		activate: [
			{ id: 0, name: 'prod_share-results_results_cta_sales_v0' },
			{
				id: 126,
				name: 'results_analyze_click_upsell_feature_addFilter_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_share-results_results_close_sales_v0' },
			{
				id: 127,
				name: 'results_analyze_click_upsell_feature_addFilter_windowClose',
			},
		],
	},
	prod_dashboards_results_click_sales_v0: {
		open: [
			{ id: 0, name: 'prod_dashboards_results_open_sales_v0' },
			{ id: 478, name: 'team_click_upsell_feature_dashboards' },
		],
		activate: [
			{ id: 0, name: 'prod_dashboards_results_cta_sales_v0' },
			{
				id: 479,
				name: 'team_click_upsell_feature_dashboards_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_dashboards_results_close_sales_v0' },
			{
				id: 480,
				name: 'team_click_upsell_feature_dashboards_closeWindow',
			},
		],
	},
	'prod_invite-members_team-invite-btn_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_invite-members_team-invite-btn_open_sales_v0' },
			{ id: 399, name: 'team_click_upsell_feature_invitemember' },
		],
		activate: [
			{ id: 0, name: 'prod_invite-members_team-invite-btn_cta_sales_v0' },
			{
				id: 400,
				name: 'team_click_upsell_feature_invitemember_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_invite-members_team-invite-btn_close_sales_v0' },
			{
				id: 401,
				name: 'team_click_upsell_feature_invitemember_closeWindow',
			},
		],
	},
	'prod_survey-mngmt_team_click_sales_v0': {
		open: [{ id: 0, name: 'prod_survey-mngmt_team_open_sales_v0' }],
		activate: [{ id: 0, name: 'prod_survey-mngmt_team_cta_sales_v0' }],
		close: [{ id: 0, name: 'prod_survey-mngmt_team_close_sales_v0' }],
	},
	'prod_member-activity_team_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_member-activity_team_open_sales_v0' },
			{ id: 442, name: 'team_click_upsell_feature_log' },
		],
		activate: [
			{ id: 0, name: 'prod_member-activity_team_cta_sales_v0' },
			{
				id: 443,
				name: 'team_click_upsell_feature_log_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_member-activity_team_close_sales_v0' },
			{
				id: 444,
				name: 'team_click_upsell_feature_log_closeWindow',
			},
		],
	},
	'prod_cust-login-page_team-settings_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_cust-login-page_team-settings_open_sales_v0' },
			{ id: 425, name: 'team_click_upsell_feature_customlogin' },
		],
		activate: [
			{ id: 0, name: 'prod_cust-login-page_team-settings_cta_sales_v0' },
			{
				id: 426,
				name: 'team_click_upsell_feature_customlogin_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_cust-login-page_team-settings_close_sales_v0' },
			{
				id: 427,
				name: 'team_click_upsell_feature_customlogin_closeWindow',
			},
		],
	},
	'prod_survey-logic_builder_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_survey-logic_builder_open_sales_v0' },
			{ id: 69, name: 'builder_click_upsell_feature_logicQuestionorder' },
		],
		activate: [
			{ id: 0, name: 'prod_survey-logic_builder_cta_sales_v0' },
			{
				id: 70,
				name: 'builder_click_upsell_feature_logicQuestionorder_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_survey-logic_builder_close_sales_v0' },
			{
				id: 71,
				name: 'builder_click_upsell_feature_logicQuestionorder_closeWindow',
			},
		],
	},
	'prod_response-limit_user-profile-upgrade_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_response-limit_user-profile-upgrade_open_sales_v0' },
			{ id: 408, name: 'header_userbubble_increase' },
		],
		activate: [
			{ id: 0, name: 'prod_response-limit_user-profile-upgrade_cta_sales_v0' },
			{
				id: 32,
				name: 'dashboard_click_upsell_feature_moreRespones_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_response-limit_user-profile-upgrade_close_sales_v0' },
			{
				id: 33,
				name: 'dashboard_click_upsell_feature_moreRespones_closeWindow',
			},
		],
	},
	'prod_response-limit_my-surveys-upgrade_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_response-limit_my-surveys-upgrade_open_sales_v0' },
			{ id: 31, name: 'dashboard_click_upsell_feature_moreRespones' },
		],
		activate: [
			{ id: 0, name: 'prod_response-limit_my-surveys-upgrade_cta_sales_v0' },
			{
				id: 32,
				name: 'dashboard_click_upsell_feature_moreRespones_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_response-limit_my-surveys-upgrade_close_sales_v0' },
			{
				id: 33,
				name: 'dashboard_click_upsell_feature_moreRespones_closeWindow',
			},
		],
	},
	'prod_response-limit_billing_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_response-limit_billing_open_sales_v0' },
			{ id: 31, name: 'dashboard_click_upsell_feature_moreRespones' },
		],
		activate: [
			{ id: 0, name: 'prod_response-limit_billing_cta_sales_v0' },
			{
				id: 32,
				name: 'dashboard_click_upsell_feature_moreRespones_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_response-limit_billing_close_sales_v0' },
			{
				id: 33,
				name: 'dashboard_click_upsell_feature_moreRespones_closeWindow',
			},
		],
	},
	'prod_response-limit_results_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_response-limit_results_open_sales_v0' },
			{ id: 31, name: 'dashboard_click_upsell_feature_moreRespones' },
		],
		activate: [
			{ id: 0, name: 'prod_response-limit_result_cta_sales_v0' },
			{
				id: 32,
				name: 'dashboard_click_upsell_feature_moreRespones_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_response-limit_result_close_sales_v0' },
			{
				id: 33,
				name: 'dashboard_click_upsell_feature_moreRespones_closeWindow',
			},
		],
	},
	'prod_response-limit_my-surveys-increase-btn_click_sales_v0': {
		open: [
			{ id: 0, name: 'prod_response-limit_my-surveys-increase-btn_click_sales_v0' },
			{ id: 31, name: 'dashboard_click_upsell_feature_moreRespones' },
		],
		activate: [
			{ id: 0, name: 'prod_response-limit_my-surveys-increase-btn_cta_sales_v0' },
			{
				id: 32,
				name: 'dashboard_click_upsell_feature_moreRespones_ActivatePremium',
			},
		],
		close: [
			{ id: 0, name: 'prod_response-limit_my-surveys-increase-btn_close_sales_v0' },
			{
				id: 33,
				name: 'dashboard_click_upsell_feature_moreRespones_closeWindow',
			},
		],
	},
	'prod_welcome-page_builder_click_sales_v0': {
		open: [{ id: 0, name: 'prod_welcome-page_builder_open_sales_v0' }],
		activate: [{ id: 0, name: 'prod_welcome-page_builder_cta_sales_v0' }],
		close: [{ id: 0, name: 'prod_welcome-page_builder_close_sales_v0' }],
	},
};
