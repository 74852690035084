import React, { FC, memo, useState, useCallback } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import Tippy from '@tippyjs/react/headless';
import { Instance, sticky as stickyPlugin } from 'tippy.js';
import {
	StyledDevider,
	StyledDefaultItem,
	StyledMenu,
	ItemProps,
	MenuProps,
	StyledDescriptionItem,
	StyledDefaultItemDiv,
	MenuChildWrapper,
} from './';
import { controlledTippyFix, pushPropsIntoAllChildren } from '../../utils';
import { Icon } from '../Icon';

const Devider: FC = memo((props) => <StyledDevider {...props} />);

const DefaultItem: FC<
	ItemProps & {
		specificContext?: boolean;
		onMouseEnter: (e: React.MouseEvent) => void;
	}
> = memo(
	({
		div,
		children,
		...rest
	}: Omit<ItemProps, 'id' | 'onClick'> & { specificContext?: boolean }) =>
		div ? (
			<StyledDefaultItemDiv {...rest}>{children}</StyledDefaultItemDiv>
		) : (
			<StyledDefaultItem {...rest}>{children}</StyledDefaultItem>
		),
);

DefaultItem.displayName = 'MenuItem';

const Item: FC<ItemProps> = memo(
	({
		active,
		children,
		closeOnClick,
		description,
		disabled,
		onClick,
		id,
		className,
		closeMenu,
		label,
		onMenuChange,
		onHover,
		renderLeft,
		variant,
		size,
		withMenu,
		...props
	}: ItemProps) => {
		const handleOnClick = useCallback(
			(e) => {
				if (!disabled) {
					onClick && onClick(id, e);
					onMenuChange && onMenuChange(id);
					!withMenu && closeOnClick && closeMenu && closeMenu();
				}
			},
			[disabled, onClick, id, onMenuChange, withMenu, closeOnClick, closeMenu],
		);

		const handleOnHover = useCallback((id: string) => () => onHover && onHover(id), [onHover]);

		const renderDefaultItem = useCallback(
			() => (
				<>
					{children && (
						<div className="itemContent">
							{pushPropsIntoAllChildren(children, { active: active ? 1 : 0 })}
						</div>
					)}
					{!children && renderLeft && <div className="renderLeft">{renderLeft}</div>}
					{!children && label && <div className="itemLabel">{label}</div>}
					{!children && withMenu && <Icon name="arrow-right-16" className="rightArrow" />}
				</>
			),
			[active, children, label, renderLeft, withMenu],
		);

		const renderWithMenu = useCallback(
			() => (
				<Menu
					popperOptions={{
						strategy: 'fixed',
						modifiers: [
							{
								name: 'flip',
								options: {
									boundary: document,
								},
							},
							{
								name: 'preventOverflow',
								options: {
									mainAxis: false,
								},
							},
						],
					}}
					disabled={disabled}
					offset={(o) => (o.placement.includes('-start') ? [-4, 0] : [4, 0])}
					placement="right-start"
					anchorWidth="100%"
					size={size}
					{...withMenu}
					items={withMenu.items.map((i) => ({ ...i, closeMenu }))}
				>
					{renderDefaultItem()}
				</Menu>
			),
			[closeMenu, disabled, renderDefaultItem, size, withMenu],
		);

		return variant === 'description' ? (
			<StyledDescriptionItem
				{...props}
				onMouseEnter={handleOnHover(`${id}`)}
				active={active}
				size={size}
				disabled={disabled}
				onClick={handleOnClick}
				className={className}
			>
				{children && <div className="itemContent">{children}</div>}
				{!children && label && <div className="label">{label}</div>}
				{!children && description && <div className="description">{description}</div>}
			</StyledDescriptionItem>
		) : (
			<DefaultItem
				{...props}
				onMouseEnter={handleOnHover(`${id}`)}
				active={active}
				size={size}
				withMenu={withMenu}
				disabled={disabled}
				onClick={handleOnClick}
				className={className}
				// id={id}
				specificContext={!!children}
			>
				{withMenu ? renderWithMenu() : renderDefaultItem()}
			</DefaultItem>
		);
	},
);

export const Menu: FC<MenuProps> = ({
	appendTo,
	anchorWidth,
	color,
	content,
	disabled,
	children,
	items,
	maxHeight,
	offset,
	onChange,
	onHide,
	onMount,
	onItemHover,
	placement,
	showOnCreate,
	onClickOutside,
	selectedId,
	sticky,
	visible,
	width,
	variant,
	zIndex,
	popperOptions,
	contentClassname,
	trigger,
	triggerTarget,
	delay,
	onHidden,
	doNotRender,
	childrenClassname,
	scrollbarsRenderView,
	autoHeight,
	...props
}: MenuProps) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState(false);
	const [menuInstance, setMenuInstance] = useState(null);
	const controlled = typeof visible === 'boolean';

	const handleOnMount = useCallback(
		(instance) => {
			!menuInstance && setMenuInstance(instance);
			setOpen(true);
			onMount && onMount();
		},
		[menuInstance, onMount],
	);

	const handleOnClose = useCallback(
		(closeParent) => () => {
			if (menuInstance) {
				menuInstance.hide();
				closeParent && closeParent();
				open && setOpen(false);
			}
		},
		[menuInstance, open],
	);

	/**
	 * 	This handling with event listeners is important, because we need to
	 * 	destroy Tippy when is completely hidden.
	 * 	Because we have our own animation, Menu (Tippy) element is staying in the DOM after
	 * 	it is closed and is not destroyed by default.
	 * 	So when trigger for hiding Menu is triggered, we add event listener
	 * 	which is called after transition ended and which cause destroying of the Menu element.
	 * 	But there is a problem when the transition is in progress and Menu element is hiding,
	 * 	but transition is not finished and we trigger event for showing
	 * 	Menu again (e.g. its duration is 200ms and we open the Menu in time 180ms). Then event listener
	 * 	is still not removed and Menu (Tippy) is destroyed after transition is finished.
	 * 	- but in this case it is the opening transition - so Menu is destroyed
	 * 	when is fully shown and then disappear.
	 * 	=> FIX - Menu is unmount only when transition is 200ms long (value is set in css) and
	 * 	because this event is still added - it is removed at the beginning of the next onHide call
	 * 	(when there is no event listener removeEventListener function do nothing).
	 *
	 * 	source: https://github.com/atomiks/tippyjs-react/issues/199
	 */
	const handleOnHide = useCallback(
		(i: Instance) => {
			const unmountInstance = (event) => {
				if (event.elapsedTime >= 0.2) {
					i.unmount();
				}
				// need to control when we remove the listener because transitionend fires multiple times
				i.popper.firstChild.removeEventListener('transitionend', unmountInstance);
			};
			i.popper.firstChild.removeEventListener('transitionend', unmountInstance);
			i.popper.firstChild.addEventListener('transitionend', unmountInstance);
			open && setOpen(false);
			onHide && onHide();
		},
		[onHide, open],
	);

	const handleOnHidden = useCallback(() => {
		onHidden && onHidden();
	}, [onHidden]);

	const handleOnClickOutside = useCallback(() => {
		onClickOutside && onClickOutside();
	}, [onClickOutside]);

	// workaround to resolve Tippy.js warning (see ./utils/tippyFix.ts)
	const controlledFix = useCallback(
		() => controlledTippyFix(visible, trigger || 'click', true),
		[trigger, visible],
	);

	return doNotRender ? (
		<>{children}</>
	) : (
		<Tippy
			{...(appendTo && { appendTo })}
			delay={delay}
			popperOptions={popperOptions}
			animation="fade"
			disabled={disabled}
			interactive={true}
			onHide={handleOnHide}
			onHidden={handleOnHidden}
			onMount={handleOnMount}
			offset={offset}
			onClickOutside={handleOnClickOutside}
			placement={placement}
			showOnCreate={showOnCreate}
			// next 3 lines are workarounds because of Tippy.js warnings
			{...(sticky && { sticky: true })}
			plugins={sticky ? [stickyPlugin] : []}
			{...controlledFix()}
			visible={visible}
			zIndex={zIndex}
			triggerTarget={triggerTarget}
			render={(attrs) => (
				<StyledMenu
					{...props}
					{...attrs}
					width={width === 'anchorWidth' ? anchorEl.offsetWidth : width}
					data-animation="fade"
					data-state={open}
					data-testid="ui-menu"
				>
					<Scrollbars
						autoHide
						autoHeight={autoHeight}
						autoHeightMax={typeof maxHeight === 'number' && maxHeight > 0 ? maxHeight : '100%'}
						{...(scrollbarsRenderView && { renderView: scrollbarsRenderView })}
					>
						{content &&
							(typeof content === 'function'
								? content({
										contentClassname,
										onChange,
										isOpen: open,
										close: handleOnClose(null),
									})
								: content)}
						{items &&
							items.map(({ devider, closeMenu, ...itemProps }: ItemProps, index) =>
								devider ? (
									<Devider key={index} />
								) : (
									<Item
										color={color}
										size={props.size}
										key={index}
										onMenuChange={onChange}
										closeMenu={handleOnClose(closeMenu)}
										variant={variant}
										onHover={onItemHover}
										active={
											['number', 'string'].includes(typeof selectedId) &&
											selectedId === itemProps.id
										}
										dark={props.dark}
										{...itemProps}
									/>
								),
							)}
					</Scrollbars>
				</StyledMenu>
			)}
		>
			<MenuChildWrapper
				style={{ width: anchorWidth }}
				ref={setAnchorEl}
				className={`${childrenClassname} menu-child-wrapper ${
					(controlled ? visible : open) ? 'openedMenu' : 'closedMenu'
				}`}
			>
				{pushPropsIntoAllChildren(children, { active: (controlled ? visible : open) ? 1 : 0 })}
			</MenuChildWrapper>
		</Tippy>
	);
};
Item.displayName = 'Item';
Item.defaultProps = {
	closeOnClick: true,
	color: 'black[100]',
	iconColor: 'grey_shades_with_blue[550]',
};
Devider.displayName = 'Devider';
Menu.displayName = 'Menu';
Menu.defaultProps = {
	anchorWidth: 'auto',
	autoHeight: true,
	doNotRender: false,
	size: 'lg',
	placement: 'bottom',
	offset: [0, 8],
	variant: 'default',
};

export default memo(Menu) as FC<MenuProps>;
export { Item, Devider };
