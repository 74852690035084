import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import cleverbridgeCountries from '~/constants/cleverbridgeCountries';
import { AB_BM_VARIANT, EXPERIMENT_STATE, EXPERIMENTS } from '~/constants';

import { mapProps } from '~/hoc/index';
import query from '~/cache/query';

const withCleverbridge = (Component) => {
	const Wrapped = ({ ownProps, user, country, isBusinessModel01 }) => (
		<Component
			{...ownProps}
			isBusinessModel01={isBusinessModel01}
			cleverbridge={
				user?.activeService?.provider === 'cleverbridge' ||
				(!isBusinessModel01 &&
					user?.activeService?.provider !== 'stripe' &&
					cleverbridgeCountries.includes(country?.code))
			}
		/>
	);

	Wrapped.propTypes = {
		ownProps: PropTypes.object,
		user: PropTypes.object,
		country: PropTypes.object,
		isBusinessModel01: PropTypes.bool,
	};

	return Wrapped;
};

export default compose(
	mapProps((ownProps) => ({ ownProps })),
	query('account', {
		fragments: ['ActiveService'],
	}),
	query('user', {
		fragments: ['Country'],
		mapProps: ({ user }) => ({
			country: user?.country,
		}),
	}),
	query('user', {
		fragments: ['Experiments'],
		mapProps: (data) => {
			const isBusinessModel01 =
				data?.user?.experiments?.some(
					(e) =>
						e.name === EXPERIMENTS.AB_BUSINESS_MODEL &&
						e.variant === AB_BM_VARIANT.V1 &&
						e.state === EXPERIMENT_STATE.INCLUDED,
				) || false;
			return { isBusinessModel01 };
		},
	}),
	withCleverbridge,
);
