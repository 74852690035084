const EXPERIMENTS = {
	SEGMENTATION: 'segmentation',
	AB_TRIAL: 'ab_trial',
	AB_PUBLISH: 'ab_publish',
	AB_TRIAL_CONVERSION: 'ab_trial_conversion',
	AB_BUSINESS_MODEL: 'ab_business_model',
	RC: 'RC',
};

const SEGMENTATION_VARIANT = {
	NEW: 'new',
	REGISTER: 'register',
	TEAM: 'team',
};

const EXPERIMENT_STATE = {
	INCLUDED: 'INCLUDED',
	SKIPPED: 'SKIPPED',
	EXCLUDED: 'EXCLUDED',
	FINISHED: 'FINISHED',
};

const AB_TRIAL_VARIANT = {
	VARIANT2: '01',
	VARIANT3: '03',
	VARIANT5: '05',
};

const AB_BM_VARIANT = {
	V1: '01',
};

export { EXPERIMENTS, EXPERIMENT_STATE, SEGMENTATION_VARIANT, AB_TRIAL_VARIANT, AB_BM_VARIANT };
