import helpers from './helpers';
import typography from './typography';
import survioColors from './survioColors';
import shadows from './shadows';

export const avatarColors = [
	'avatar_colors.blue_2[300]',
	'avatar_colors.blue_2[500]',
	'avatar_colors.blue_2[600]',
	'avatar_colors.blue_3[300]',
	'avatar_colors.blue_3[500]',
	'avatar_colors.blue_3[600]',
	'avatar_colors.blue[300]',
	'avatar_colors.blue[500]',
	'avatar_colors.blue[600]',
	'avatar_colors.turquoise_blue[300]',
	'avatar_colors.turquoise_blue[500]',
	'avatar_colors.turquoise_blue[600]',
	'avatar_colors.turquoise_green[300]',
	'avatar_colors.turquoise_green[500]',
	'avatar_colors.turquoise_green[600]',
	'avatar_colors.green_yellow[300]',
	'avatar_colors.green_yellow[500]',
	'avatar_colors.green_yellow[600]',
	'avatar_colors.yellow[300]',
	'avatar_colors.yellow[500]',
	'avatar_colors.yellow[600]',
	'avatar_colors.orange[300]',
	'avatar_colors.orange[500]',
	'avatar_colors.orange[600]',
	'avatar_colors.red[300]',
	'avatar_colors.red[500]',
	'avatar_colors.red[600]',
	'avatar_colors.pink[300]',
	'avatar_colors.pink[500]',
	'avatar_colors.pink[600]',
	'avatar_colors.purple[300]',
	'avatar_colors.purple[500]',
	'avatar_colors.purple[600]',
] as const;

export const avatarColorSets = [
	'blue_3',
	'blue_2',
	'blue',
	'turquoise_blue',
	'turquoise_green',
	'green_yellow',
	'yellow',
	'orange',
	'red',
	'pink',
	'purple',
] as const;

const allColorList = [
	...avatarColors,
	'active_blue[100]',
	'active_blue[200]',
	'active_blue[400]',
	'active_blue[600]',
	'active_blue[700]',
	'black[10]',
	'black[20]',
	'black[30]',
	'black[40]',
	'black[50]',
	'black[60]',
	'black[70]',
	'black[80]',
	'black[90]',
	'black[100]',
	'grey[200]',
	'grey[300]',
	'grey[400]',
	'brand_blue[500]',
	'brand_blue[600]',
	'brand_blue[700]',
	'green_success[100]',
	'green_success[400]',
	'green_success[500]',
	'green_success[600]',
	'green_success[700]',
	'grey_shades_with_blue[5]',
	'grey_shades_with_blue[10]',
	'grey_shades_with_blue[25]',
	'grey_shades_with_blue[50]',
	'grey_shades_with_blue[100]',
	'grey_shades_with_blue[200]',
	'grey_shades_with_blue[250]',
	'grey_shades_with_blue[300]',
	'grey_shades_with_blue[350]',
	'grey_shades_with_blue[400]',
	'grey_shades_with_blue[500]',
	'grey_shades_with_blue[550]',
	'grey_shades_with_blue[600]',
	'grey_shades_with_blue[700]',
	'grey_shades_with_blue[800]',
	'ice[100]',
	'red[100]',
	'red[150]',
	'red[200]',
	'red[250]',
	'red[300]',
	'red[400]',
	'red[500]',
	'red[550]',
	'red[600]',
	'red[700]',
	'red[800]',
	'yellow[50]',
	'yellow[100]',
	'yellow[200]',
	'yellow[250]',
	'yellow[300]',
	'yellow[400]',
	'yellow[500]',
	'yellow[600]',
	'yellow[700]',
	'yellow[800]',
	'yellow[900]',
	'yellow[1000]',
	'white',
	'gold[100]',
	'gold[200]',
	'gold[300]',
	'gold[400]',
	'gold[500]',
	'gold[600]',
	'gold[700]',
	'blueberry[500]',
	'blueberry[700]',
	'blueberry[800]',
	'blueberry[900]',
	'brand_steel[100]',
	'brand_steel[200]',
	'brand_steel[300]',
	'brand_steel[400]',
	'brand_steel[500]',
	'brand_steel[600]',
	'brand_steel[700]',
	'orange[500]',
	'orange[600]',
	'orange[700]',
	'primary[100]',
	'primary[150]',
	'primary[175]',
	'primary[200]',
	'primary[225]',
	'primary[250]',
	'primary[2501]',
	'primary[275]',
	'primary[285]',
	'primary[300]',
	'primary[400]',
	'primary[500]',
	'primary[600]',
	'primary[700]',
	'primary[800]',
	'primary[850]',
	'primary[900]',
	'secondary[100]',
	'secondary[200]',
	'secondary[300]',
	'secondary[400]',
	'secondary[500]',
	'secondary[600]',
	'secondary[700]',
	'secondary[900]',
	'upgrade[300]',
	'upgrade[400]',
	'upgrade[500]',
	'upgrade[600]',
	'upgrade[650]',
	'upgrade[700]',
	'upgrade[900]',
	'fountain_blue[500]',
	'fountain_blue[600]',
	'question_colors[1]',
	'question_colors[2]',
	'question_colors[3]',
	'outline[100]',
	'outline[64]',
	'outline[40]',
	'outline[20]',
	'outline[10]',
] as const;

const themeBase = {
	helpers,
	typography,
};

const survioTheme = {
	colors: survioColors,
	shadows,
	...themeBase,
};

// const proTheme = {
// 	colors: proColors,
// 	...themeBase,
// };

export type AvatarColorSetType = (typeof avatarColorSets)[number];
export type ColorType = (typeof allColorList)[number];
export type ThemeType = typeof survioTheme;
export { survioTheme };
